<template>
    <nut-popup
        :style="{
            top: position.top,
            left: position.left,
            color: props.color,
            fontWeight: props.fontWeight,
            fontSize: props.fontSize,
        }"
        :teleport="props.teleport"
        v-model:visible="show"
        pop-class="popup-directive-root"
    >
        {{ props.text }}
    </nut-popup>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import { show } from "./index";
import { isElement } from "lodash";
import { onUnmounted } from "vue";

const position = ref({ top: "0px", left: "0px" });
const props = defineProps({
    color: String,
    fontWeight: String,
    fontSize: String,
    text: String,
    teleport: String,
    teleportElement: Element,
});
onMounted(() => {
    if (isElement(props.teleportElement)) {
        const rect = props.teleportElement!.getBoundingClientRect();
        position.value.top = rect.y - 5 + "px";
        position.value.left = rect.left - 10 + "px";
    }
});
onUnmounted(() => {
    document.body.classList.remove("nut-overflow-hidden");
    if (document.body.classList.length === 0) {
        document.body.removeAttribute("class");
    }
});
</script>
<style lang="scss">
.popup-directive-root {
    color: $font-content-color;
    padding: 5px 10px;
    max-width: 70vw;
    border-radius: $card-radius;
    transform: none;
    word-break: break-all;
}
</style>
